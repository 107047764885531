import { ViewerMode } from "awesome-pdf-viewer/dist/viewer/ViewerBase";
import * as React from "react";
import {DownloadDocumentsConstants } from '../../Common/Constants';
import { match } from "react-router";
import { Viewer } from "../../../components/Common/PdfView/Viewer";
import { Body } from "../../../components/Common/StepLayout/Body";
import { StepLayout } from "../../../components/Common/StepLayout/StepLayout";
import { DocumentGroups } from "../../../core/domain/models/IGroup";
import { ITutorialInfo } from "../../../core/domain/models/ITutorialInfo";
import { IAttachmentDownloadViewModel } from "../../../core/domain/viewModels/IAttachmentDownloadViewModel";
import {
  IDocumentReviewModel,
  ReviewDocumentType,
} from "../../../core/domain/viewModels/IDocumentReviewModel";
import { AttachmentsView } from "./AttachmentsView";
import { PdfViewer } from "./PdfViewer";
import { AddCCRecipientModal } from "../../CCRecipient/AddCCRecipientModal";
import {
  ICCRecipientModel,
  ICCRecipientDownloadableDocuments,
  CCRecipientDocumentGroups,
} from "../../../core/domain/models/ICCRecipientModel";
import { SelectiveDownload } from "../../Download/SelectiveDownload";
import { logger } from "../../../routes";
import {
  NextButtonIcon,
  SvgDownloadButtonIcon,
  SvgForwardButtonIcon,
} from "../../../components/Common/Icons/SvgIcons";
import { Common } from "../../../components/Common/Constants";
import { PaperFileConsentModal } from "../../../components/PaperFile/PaperFileConsentModal";
import { ISummaryViewModel } from "../../../core/domain/viewModels/ISummaryViewModel";
import { ClientTypes } from "../../../store/SSN/SSNStore_GR";
import { Role } from "../../../core/common/Enums";
import { IHeaderInfoViewModel } from "../../../core/domain/viewModels/IHeaderInfoViewModel";
import { MarsNotifier } from "../../../components/Common/Notification/MarsNotifier";

interface IReviewWizardProps {
  documentReviewModel: IDocumentReviewModel[];
  downloadAttachment: (
    clientId: string,
    attachment: IAttachmentDownloadViewModel
  ) => void;
  downloadAllAttachments: (
    clientId: string,
    attachments: IAttachmentDownloadViewModel[]
  ) => void;
  requestPreviewDocuments?: (clientId: string) => void;
  prevStep: () => void;
  nextStep: () => void;
  onHeaderAndFooterVisibility: (visibility: boolean) => void;
  match: match;
  hideTitle: boolean;
  tutorialInfo: ITutorialInfo;
  hideReviewTutorial: (clientId: string, doNotShowNextTime: boolean) => any;
  closeCCRecipientModal?: () => void;
  showCCRecipientModal?: () => void;
  showModal?: boolean;
  ccRecipientDownloadableDocuments?: ICCRecipientDownloadableDocuments[];
  taxdocumentId?: number;
  forwardDocumentsToRecipients?: (
    clientId: string,
    recipientInfo: ICCRecipientModel,
    callback: () => void
  ) => void;
  downloadAllDocuments?: () => void;
  downloadSelectedDocuments?: (documentGroups: number[]) => void;
  showDownloadButton?: boolean;
  isPreviewMode: boolean;
  summaryData: ISummaryViewModel;
  headerInfo: IHeaderInfoViewModel;
  updatePaperFileConsent: (clientId: string, callback?: () => void) => void;
  skipButtonText: string;
  isDraftReturn: boolean;
}

export enum ReviewTabs {
  FilingInstructions = 1,
  TaxReturns,
  Attachments,
  PaperFileReturns,
}

export interface IReviewDocumentKeyValue {
  tabName: string;
  tabIndex: number;
}

interface IReviewWizardStates {
  currentStep: number;
  showCloseTutorialPopover: boolean;
  activeTabs: IReviewDocumentKeyValue[];
  isDoNotShowCkbChecked: boolean;
  showDownloadModal: boolean;
  isConsented: boolean;
  showPaperFileConsent: boolean;
  isController: boolean;
  documentsToDownload: number[];
}

export enum ReviewTabNames {
  "Filing Instructions" = 1,
  "Tax Returns" = 2,
  "Attachments" = 3,
  "Paper File Returns" = 4,
}

const autoIds: any = {};
autoIds["1"] = "1A7C1A5B-3330-41EB-BFD1-6888320671DC";
autoIds["2"] = "9303C576-2CA9-466A-97F1-9064CF9D86BA";
autoIds["3"] = "811EEF04-7D1E-4B00-9599-09B3AC081A5D";
autoIds["4"] = "3090E4AD-BC7C-478D-8AF3-8BC5E6580D36";

export class ReviewWizardBody extends React.Component<
  IReviewWizardProps,
  IReviewWizardStates
> {
  constructor(props: IReviewWizardProps) {
    super(props);
    this.state = {
      currentStep: ReviewTabs.FilingInstructions,
      showCloseTutorialPopover: true,
      activeTabs: [],
      isDoNotShowCkbChecked: false,
      showDownloadModal: false,
      isConsented: false,
      showPaperFileConsent: false,
      isController: false,
      documentsToDownload: [],
    };
    this.onPrevious = this.onPrevious.bind(this);
    this.onNext = this.onNext.bind(this);
    this.getActiveTabs = this.getActiveTabs;
    this.getNavBar = this.getNavBar.bind(this);
    this.getActiveTabDetails = this.getActiveTabDetails.bind(this);
  }

  componentDidMount() {
    const param: any = this.props.match.params;
    this.props.requestPreviewDocuments &&
      (!this.props.documentReviewModel ||
        this.props.documentReviewModel.length === 0) &&
      this.props.requestPreviewDocuments(param.clientId);
    if (this.props.headerInfo && this.props.headerInfo.loggedInUserInfo) {
      this.setState({
        isController:
          this.props.headerInfo.loggedInUserInfo.role.toString() ===
          Role[Role.Controller].toString(),
      });
    }

    if (this.props.summaryData && this.props.summaryData.paperFileConsentInfo) {
      this.setState({
        isConsented: this.props.summaryData.paperFileConsentInfo.isConsented,
      });
    }
    logger.trackPageView("Review Taxdocument Page", {
      ClientId: param.clientId,
      DocumentId: this.props.taxdocumentId,
      PreviewMode: this.props.isPreviewMode,
    });
  }

  static getDerivedStateFromProps(
    nextProps: IReviewWizardProps,
    prevState: IReviewWizardStates
  ) {
    if (
      nextProps.documentReviewModel.length > 0 &&
      prevState.activeTabs.length == 0
    ) {
      if (
        nextProps.documentReviewModel.findIndex(
          (x) => x.documentGroup == DocumentGroups.Transmittals
        ) != -1
      ) {
        return { currentStep: ReviewTabs.FilingInstructions };
      } else if (
        nextProps.documentReviewModel.findIndex(
          (x) => x.documentGroup == DocumentGroups.TaxReturns
        ) != -1
      ) {
        return { currentStep: ReviewTabs.TaxReturns };
      } else if (
        nextProps.documentReviewModel.findIndex(
          (x) =>
            x.documentGroup == DocumentGroups.None &&
            x.documentType == ReviewDocumentType.Attachment
        ) != -1
      ) {
        return { currentStep: ReviewTabs.Attachments };
      } else if (
        nextProps.documentReviewModel.findIndex(
          (x) =>
            x.documentGroup == DocumentGroups.None &&
            x.documentType == ReviewDocumentType.PaperFileReturn
        ) != -1
      ) {
        return { currentStep: ReviewTabs.PaperFileReturns };
      }
    }

    return null;
  }

  private handleTabSelect = (index: any) => {
    const param: any = this.props.match.params;
    this.setState({ currentStep: index }, () => {
      logger.trackTrace(`Review Page --> ${ReviewTabs[index]} Tab Selected`, {
        ClientId: param.clientId,
        DocumentId: this.props.taxdocumentId,
        PreviewMode: this.props.isPreviewMode,
      });
    });
  };

  getActiveTabs(nextProps: IReviewWizardProps) {
    let activeTabs: IReviewDocumentKeyValue[] = [];
    let formIndex: number = nextProps.documentReviewModel.findIndex(
      (x) => x.documentGroup == DocumentGroups.Transmittals
    );
    formIndex != -1
      ? activeTabs.push({ tabName: ReviewTabNames[1], tabIndex: 1 })
      : "";
    formIndex = nextProps.documentReviewModel.findIndex(
      (x) => x.documentGroup == DocumentGroups.TaxReturns
    );
    formIndex != -1
      ? activeTabs.push({ tabName: ReviewTabNames[2], tabIndex: 2 })
      : "";
    formIndex = nextProps.documentReviewModel.findIndex(
      (x) =>
        x.documentGroup == DocumentGroups.None &&
        x.documentType == ReviewDocumentType.Attachment
    );
    formIndex != -1
      ? activeTabs.push({ tabName: ReviewTabNames[3], tabIndex: 3 })
      : "";
    formIndex = nextProps.documentReviewModel.findIndex(
      (x) =>
        x.documentGroup == DocumentGroups.None &&
        x.documentType == ReviewDocumentType.PaperFileReturn
    );
    formIndex != -1
      ? activeTabs.push({ tabName: ReviewTabNames[4], tabIndex: 4 })
      : "";
    this.setState({ activeTabs: activeTabs });
  }

  getNavBar() {
    let currentStep: number = this.state.currentStep;
    let activeTabName = ReviewTabNames[currentStep];
    if (
      this.props.documentReviewModel.length > 0 &&
      this.state.activeTabs.length == 0
    ) {
      this.getActiveTabs(this.props);
    }
    let navBars: any = this.state.activeTabs.map((tabs, index) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          key={tabs.tabIndex + tabs.tabName}
          className={
            activeTabName == tabs.tabName
              ? "right-pointer cursor-pointer"
              : "cursor-pointer"
          }
          id="review-tab-navbar"
          data-test-auto={autoIds[tabs.tabIndex.toString()]}
          onClick={() => this.handleTabSelect(tabs.tabIndex)}
        >
          <div>{tabs.tabName}</div>
          {activeTabName == tabs.tabName && (
            <NextButtonIcon className="margin-right-10" />
          )}
        </div>
      );
    });
    return navBars;
  }

  getNextStep(activeIndex: number): number {
    let nextStepIndex = -1;
    if (
      this.state.activeTabs.findIndex(
        (x) => x.tabName == ReviewTabNames[activeIndex]
      ) != -1
    ) {
      nextStepIndex = activeIndex;
    } else {
      nextStepIndex =
        this.state.activeTabs.filter((x) => x.tabIndex > activeIndex).length > 0
          ? this.state.activeTabs.filter((x) => x.tabIndex > activeIndex)[0]
              .tabIndex
          : -1;
    }
    return nextStepIndex;
  }

  getPreviousStep(activeIndex: number): number {
    let prevStepIndex = -1;
    if (
      this.state.activeTabs.findIndex(
        (x) => x.tabName == ReviewTabNames[activeIndex]
      ) != -1
    ) {
      prevStepIndex = activeIndex;
    } else {
      let len = this.state.activeTabs.filter(
        (x) => x.tabIndex < activeIndex
      ).length;
      if (len > 0) {
        prevStepIndex = this.state.activeTabs.filter(
          (x) => x.tabIndex === activeIndex - 1
        )[0]?.tabIndex;
      }
    }
    return prevStepIndex;
  }

  onPrevious(index: number) {
    if (index > 1) {
      let tabsActive = this.state.activeTabs.length;
      if (tabsActive > 1) {
        let activeIndex: number = index - 1;
        activeIndex = this.getPreviousStep(activeIndex);
        if (activeIndex != -1) {
          this.handleTabSelect(activeIndex);
          return;
        }
        this.props.prevStep();
      } else {
        this.props.prevStep();
      }
    } else {
      this.props.prevStep();
    }
  }

  onNext(index: number) {
    if (index <= this.state.activeTabs.length) {
      let tabsActive = this.state.activeTabs.length;
      if (tabsActive > 1) {
        let activeIndex: number = index + 1;
        activeIndex = this.getNextStep(activeIndex);
        if (activeIndex != -1) {
          this.handleTabSelect(activeIndex);
          return;
        }
        this.props.nextStep();
      } else {
        this.props.nextStep();
      }
    } else {
      this.props.nextStep();
    }
  }

  handleViewModeChange = (viewerMode: ViewerMode) => {
    if (viewerMode == ViewerMode.Read) {
      this.props.onHeaderAndFooterVisibility(true);
    } else {
      this.props.onHeaderAndFooterVisibility(false);
    }
  };
  getActiveTab() {
    let document: IDocumentReviewModel;
    if (this.props.documentReviewModel.length > 0) {
      switch (this.state.currentStep) {
        case ReviewTabs.Attachments:
      }
    }
  }

  getActiveTabDetails() {
    let document: IDocumentReviewModel;
    if (this.props.documentReviewModel.length > 0) {
      switch (this.state.currentStep) {
        case ReviewTabs.FilingInstructions:
          document = this.props.documentReviewModel.filter(
            (x) => x.documentGroup == DocumentGroups.Transmittals
          )[0];
          return (
            <Viewer
              leftPanel={this.getNavBar()}
              url={document && document.fileLink}
              onViewModeChange={this.handleViewModeChange}
            />
          );

        case ReviewTabs.TaxReturns:
          document = this.props.documentReviewModel.filter(
            (x) => x.documentGroup == DocumentGroups.TaxReturns
          )[0];
          return (
            <Viewer
              leftPanel={this.getNavBar()}
              url={document && document.fileLink}
              onViewModeChange={this.handleViewModeChange}
            />
          );

        case ReviewTabs.Attachments:
          let attachmentsInfo: IDocumentReviewModel[] = [];
          attachmentsInfo = this.props.documentReviewModel.filter(
            (x) =>
              x.documentGroup == DocumentGroups.None &&
              x.documentType == ReviewDocumentType.Attachment
          );
          return (
            <AttachmentsView
              attachmentReviewModel={attachmentsInfo}
              match={this.props.match}
              downloadAttachment={this.props.downloadAttachment}
              downloadAllAttachments={this.props.downloadAllAttachments}
              isPreviewMode={this.props.isPreviewMode}
              isDraftReturn={this.props.isDraftReturn}
            />
          );

        case ReviewTabs.PaperFileReturns:
          document = this.props.documentReviewModel.filter(
            (x) =>
              x.documentGroup == DocumentGroups.None &&
              x.documentType == ReviewDocumentType.PaperFileReturn
          )[0];
          return (
            <Viewer
              leftPanel={this.getNavBar()}
              url={document && document.fileLink}
              onViewModeChange={this.handleViewModeChange}
            />
          );
      }
    }
  }

  getActiveTabName = () => {
    return ReviewTabNames[this.state.currentStep];
  };

  getActiveWidth = () => {
    switch (this.state.currentStep) {
      case 3:
        return 12;
      case 2:
      case 1:
        return 12;
      default:
        return 12;
    }
  };

  getActiveClassName = () => {
    let activeClass = "";
    switch (this.state.currentStep) {
      case 3:
        activeClass = "no-padding";
        break;

      case 2:
      case 1:
        activeClass = "no-padding";
        break;
      default:
        activeClass = "no-padding";
        break;
    }
    return activeClass + " review-step-container";
  };

  onTutorialDone = () => {
    const param: any = this.props.match.params;
    if (param.clientId) {
      this.props.hideReviewTutorial(
        param.clientId,
        this.state.isDoNotShowCkbChecked
      );
    }
  };

  onDoNotShowCkbChecked = (event: any) => {
    this.setState({ isDoNotShowCkbChecked: event.target.checked });
  };

  onDownloadClick = () => 
  {if(!this.props.isPreviewMode)
    {
      this.setState({ showDownloadModal: true });
    }
    else
    {
      MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
    }
  };
  
  onHide = () => {
    this.setState({ showDownloadModal: false });
  };

  reArrangeDownloadableDocumentsOrder = (
    downloadableDocuments: ICCRecipientDownloadableDocuments[]
  ) => {
    let data: ICCRecipientDownloadableDocuments[] = [...downloadableDocuments];
    let index: number = downloadableDocuments.findIndex(
      (x) => x.documentGroup === CCRecipientDocumentGroups.PaperFiles
    );
    if (index !== -1) {
      let efileIndex: number = downloadableDocuments.findIndex(
        (x) => x.documentGroup === CCRecipientDocumentGroups.SignedEFile
      );
      efileIndex = efileIndex !== -1 ? efileIndex + 1 : 0;
      data?.splice(efileIndex, 0, downloadableDocuments[index]);
      data?.splice(index + 1, 1);
    }
    return data;
  };

  onDownloadSelectedDocuments = (documents: number[]) => {
    if (
      documents.filter((x) => x === CCRecipientDocumentGroups.PaperFiles)
        .length > 0 &&
      !this.state.isConsented
    ) {
      this.setState({
        showPaperFileConsent: true,
        documentsToDownload: documents,
      });
    } else {
      this.props.downloadSelectedDocuments(documents);
    }
  };

  onDownloadAll = () => {
    if (
      this.props.documentReviewModel.filter(
        (x) => x.documentType === ReviewDocumentType.PaperFileReturn
      ).length > 0 &&
      !this.state.isConsented
    ) {
      this.setState({ showPaperFileConsent: true });
    } else {
      this.props.downloadAllDocuments();
    }
  };

  onPaperFileConsentAgreed = (clientId: string) => {
    this.setState(
      {
        isConsented: true,
        showPaperFileConsent: false,
      },
      () => {
        this.props.summaryData.paperFileConsentInfo.isConsented = true;
        if (this.state.isController) {
          this.props.summaryData.paperFileConsentInfo.clientType =
            ClientTypes.Controller;
        } else {
          this.props.summaryData.paperFileConsentInfo.name =
            this.props.headerInfo.clientName;
        }
        this.props.updatePaperFileConsent(clientId);
        if (this.state.documentsToDownload.length > 0) {
          this.props.downloadSelectedDocuments(this.state.documentsToDownload);
        } else {
          this.props.downloadAllDocuments();
        }
      }
    );
  };

  onConsentSkip = () => {
    this.setState({ showPaperFileConsent: false });
  };

  handleCcRecipientAction = () => {
      if(!this.props.isPreviewMode)
        {
          this.props.showCCRecipientModal();
        }
        else
        {
          MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
  };

  public render() {
    const downloadableDocuments =
      this.props.ccRecipientDownloadableDocuments &&
      this.reArrangeDownloadableDocumentsOrder(
        this.props.ccRecipientDownloadableDocuments
      );

    return (
      <StepLayout
        className={this.getActiveClassName()}
        lg={this.getActiveWidth()}
        md={6}
        sm={6}
        xs={6}
        title={
          this.props.hideTitle
            ? undefined
            : `Review Tax Documents - ${this.getActiveTabName()}`
        }
        showDownloadButton={this.props.showDownloadButton}
        handleDownload={this.onDownloadClick}
        customDownloadTooltip="Download your tax documents."
        customButtonText={Common.Wizard.ForwardDocuments}
        showCustomButton={true}
        handleCustomButtonClick={this.handleCcRecipientAction}
        customButtonIcon={SvgForwardButtonIcon}
        customButtonTooltip={"Forward a copy of your tax documents."}
        disableCustomButton={this.props.isDraftReturn}
      >
        <Body
          xl={this.state.currentStep === ReviewTabs.Attachments ? 7 : 12}
          lg={this.state.currentStep === ReviewTabs.Attachments ? 12 : 12}
          md={this.state.currentStep === ReviewTabs.Attachments ? 12 : 12}
          sm={this.state.currentStep === ReviewTabs.Attachments ? 12 : 12}
          xs={this.state.currentStep === ReviewTabs.Attachments ? 12 : 12}
          containerClassName={
            "row" + (this.state.currentStep === 3 ? " attachment-padding" : "")
          }
          automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
        >
          <div style={{ height: "inherit" }}>
            <PdfViewer data-test-auto="4F3F7B87-7A53-4774-BDBA-1977E1BD6962">
              {this.getActiveTabDetails()}
            </PdfViewer>
          </div>
        </Body>

        <AddCCRecipientModal
          show={this.props.showModal}
          closeCCRecipientModal={this.props.closeCCRecipientModal}
          downloadableDocuments={downloadableDocuments}
          taxdocumentId={this.props.taxdocumentId}
          forwardDocuments={this.props.forwardDocumentsToRecipients}
          match={this.props.match}
        />

        <SelectiveDownload
          showModal={this.state.showDownloadModal}
          downloadableDocuments={downloadableDocuments}
          onHide={this.onHide}
          downloadAllDocuments={this.onDownloadAll}
          downloadSelectedDocuments={this.onDownloadSelectedDocuments}
        />

        <PaperFileConsentModal
          match={this.props.match}
          showModal={this.state.showPaperFileConsent}
          onConsentAgreed={this.onPaperFileConsentAgreed}
          onConsentSkip={this.onConsentSkip}
          skipButtonText={this.props.skipButtonText}
        />
      </StepLayout>
    );
  }
}
